:root {
  --main-bg-color: #ffeded;
}
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,500;0,700;0,900;1,100;1,300&display=swap");
@viewport {
  width: device-width;
  zoom: 1;
}

@-ms-viewport {
  width: device-width;
}

html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

/* GENERAL */
.noDecoration {
  text-decoration: none;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
}
.centerCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100% !important;
  position: relative;
}
.noDecoration {
  text-decoration: none;
}
canvas {
  width: 100vw;
  height: 100vh;
  z-index: 0;
}
.whiteText {
  color: white;
}
.col {
  display: flex;
  flex-direction: column;
}
.textGaramond {
  font-family: "Cormorant Garamond", serif !important;
}
.textGaramondBold {
  font-family: "Cormorant Garamond", serif !important;
  font-weight: 700 !important;
}
.centerWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.rightText {
  text-align: right;
}
.smallText {
  font-size: 12px;
}
.smallIshText {
  font-size: 14px;
}

.relative {
  position: relative;
  width: 100vw;

  height: 100vh;
}
.transitionTime {
  transition: all 0.7s;
}
.transitionTimeLong {
  transition: all 1.4s;
}
.image {
  width: 100%;
}
.width100 {
  width: 100%;
}
.midPadding {
  padding: 40px 0px;
  height: min-content;
  transition: all 0.6s;
}
.centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
}
.centerVertical {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
}
.centerHorizontal {
  display: flex;
  align-items: center;
  text-align: center;
}
b {
  font-weight: 500;
}
.centerText {
  text-align: center;
}
.blackBackground {
  background: black;
}

.noMargin {
  margin: 0px;
}
.mainMargin {
  width: 72%;
  padding: 75px 14%;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.biCol {
  width: 49%;
  display: flex;
  flex-direction: column;
}
.lCol {
  margin-right: 1%;
}
.rCol {
  margin-left: 1%;
}

.regularTitle {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 4px;
  margin-top: 10px;
}
.smallTitle {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 4px;
  margin-top: 4px;
}
.genText {
  font-size: 15px;
}
.whiteLink {
  text-decoration: none;
  color: white;
  transition: all 0.6s;
  margin-top: 7px;
  font-size: 15px;
}
.whiteLinkBorder {
  padding-bottom: 3px;
  border-bottom: white 1px solid;
}
.whiteLink:active {
  color: white;
}
.whiteLink:hover {
  opacity: 0.6;
}

.maroonLink {
  text-decoration: none;
  color: #800000;
  transition: all 0.6s;
}
.maroonLinkBorder {
  padding-bottom: 5px;
  border-bottom: #800000 1px solid;
}
.maroonLink:active {
  color: #800000;
}
.maroonLink:hover {
  opacity: 0.6;
}
/* TITLE PAGE */

.titlePageBottomLeft {
  position: absolute;
  left: 35px;
  bottom: 35px;
  border-left: solid 1px white;
  padding-left: 6px;
  padding-top: 9px;
}
.titlePage {
  display: flex;
  flex-direction: column;
}
.cardOuter {
  height: min-content;
  min-height: 240px;
  border-radius: 10px;
  background: #f5dbdb;
  width: 23%;
  margin-top: 32px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.6s;
  margin-right: 2%;
}
.cardOuter-enter {
  opacity: 0;
}
.cardOuter-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.cardOuter-exit {
  opacity: 1;
}
.cardOuter-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
.lCard {
  margin-right: 2%;
}
.cCard {
  margin-left: 1%;
  margin-right: 1%;
}
.rCard {
  margin-left: 2%;
}
.cardInner {
  width: 86%;
  padding: 20px 7%;
}

/* .landingLogo {
  width: 200px;
  position: absolute;
  margin-top: 0px;
} */

/* TITLE PAGE: CARD SELECTOR */
.cardSelect {
  margin-top: 15px;
  padding-bottom: 17px;
  border-bottom: solid 1px #210000;
  display: flex;
  flex-direction: row;
  /* padding: 5px 6px;
    width: min-content;
    border-radius: 60px;
    background-color: #800000;
    -moz-box-shadow:    inset 0px 8px 24px rgba(149, 157, 165, 0.2);
    -webkit-box-shadow: inset 0px 8px 24px rgba(149, 157, 165, 0.2);
    box-shadow:         inset 0px 8px 24px rgba(149, 157, 165, 0.2); */
}
.cardSelectBox {
  margin: 0px 6px;
  border-radius: 25px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.6s;
  cursor: pointer;
}
.cardSelectBox:hover {
  opacity: 0.6;
}
.cardSelectText {
  padding: 10px 20px;
  font-weight: 500;
  color: white;
}
.sponsorBar {
  width: 100%;
  padding: 30px 0px;
  padding-bottom: 60px;
}
.landingLogos {
  width: 80px;
  padding-bottom: 7px;
  margin-top: -70px;
}
.landingMainText {
  color: white;
  font-size: 60px;
  margin-top: -10px;
  margin-bottom: 4px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}
.landingMainTextSizing {
  font-size: 20px;
  margin-top: -10px;
  margin-bottom: 4px;
}

.landingSubText {
  color: white;
  font-size: 14px;
  font-weight: 100;

  /* display: flex;
  align-items: center; */
  /* gap: 4px; */
}
.landingSubTextAlt {
  color: white;
  font-size: 14px;
  font-weight: 100;
  margin-top: -10px;


  /* display: flex;
  align-items: center; */
  /* gap: 4px; */
}
.landingSubTextAlt2 {
  color: white;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: -20px;
  



  /* display: flex;
  align-items: center; */
  /* gap: 4px; */
}
.titleWidth {
  width: 80%;
  margin: 0px 10%;
}
.titlePage {
  display: flex;
  flex-direction: column;
}
.mainContent {
  position: relative;
  z-index: 4;
  height: auto;
  background: var(--main-bg-color); /* fallback for old browsers */
}
.cardMinHeight {
  min-height: 550px;
  display: flex;
  flex-direction: row;
}
.landingDownArrow {
  width: 40px;
  left: 50%;
  margin-left: -20px;
  bottom: 20px;
  position: absolute;
  cursor: pointer;
  transition: all 0.6s;
}
.landingDownArrow:hover {
  opacity: 0.5;
}
.medImageContainer {
  width: 90%;
  padding: 0px 5%;
}
.smlImageContainer {
  width: 60%;
  padding: 0px 20%;
}
.quickLinkCard {
  text-decoration: none;
  margin: 6px 0px;
  margin-right: 14px;
  background: #800000;
  border-radius: 5px;
  transition: all 0.6s;
  cursor: pointer;
}
.quickLinkCardInner {
  padding: 9px 11px;
}
.quickLinkCard:hover {
  transform: scale(0.95);
}
/* @keyframes cursor-blink {
  0% {
    opacity: 0;
  }
} */

/* .landingSubText::after {
  margin-top: 4px;
  content: "";
  width: 5px;
  height: 15px;
  background: #ffffff;
  display: inline-block;
  animation: cursor-blink 1.5s steps(2) infinite;
} */
/* MENU */
.menu {
  z-index: 10;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-end;
}
.menuAlt {
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.directionRow {
  flex-direction: row;
}
.directionColumn {
  flex-direction: column;
}
.innerMenu {
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
}
.menuBox {
  padding: 35px 6px;
  margin: 0px 5px;
}

.menuText {
  color: white;
  font-weight: 400;
  font-size: 15px;
  transition: all 0.6s;
  cursor: pointer;
}

.menuText:hover {
  opacity: 0.6;
}

.sponsorDiv {
  height: 140px;
}
.sponsorImg {
  height: 100%;
  margin: 30px 5%;
  margin-top: 10px;
}
/* POPUP */

.popupFront {
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(59, 59, 59, 0.404);
}
.innerPopupFront {
  width: 40vw;
  height: 30vh;
  margin: 35vh 30vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: #480000;
  color: white;
  border-radius: 9px;
  z-index: 2002;
  position: relative;
}
.closePopupFront {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

/* GOVERNANCE */
.governanceMain {
  text-align: center;
  width: 96%;
  margin: 0px 2%;
  height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* ANIMATION SHIT */
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0.1;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.1;
  }
}

.mainPulse {
  -webkit-animation: pulse 5s infinite ease-in-out;
  -o-animation: pulse 5s infinite ease-in-out;
  -ms-animation: pulse 5s infinite ease-in-out;
  -moz-animation: pulse 5s infinite ease-in-out;
  animation: pulse 5s infinite ease-in-out;
}

@-webkit-keyframes dPulse {
  0% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(0.9);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@keyframes dPulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.mainPulse {
  -webkit-animation: dPulse 5s infinite ease-in-out;
  -o-animation: dPulse 5s infinite ease-in-out;
  -ms-animation: dPulse 5s infinite ease-in-out;
  -moz-animation: dPulse 5s infinite ease-in-out;
  animation: dPulse 5s infinite ease-in-out;
}
.fadeIn {
  animation: fadeInAnimation ease 0.6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fadeOut {
  animation: fadeOutAnimation ease 0.6s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 960px) {
  .innerPersonPopup {
    width: 94%;
    height: 90vh;
    margin: 5vh 3%;
    position: relative;
    display: flex;
    justify-content: center;
  }
  .infoImg {
    width: 90%;
    margin: 0px 5%;
    cursor: pointer;
    transition: all 0.6s;
  }
}
@media screen and (max-width: 800px) {
  .row {
    flex-direction: column;
  }
  .biCol,
  .lCol {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0px;
    align-items: center;
    padding: 0px;
    flex-direction: column;
    text-align: center;
  }
  .mobileView {
    display: block;
  }
  .warpReverse {
    flex-wrap: wrap-reverse;
  }
  .image {
    width: 70%;
    padding: 15%;
  }
  .mainMargin {
    width: 94%;
    padding: 75px 3%;
  }
  .cardOuter {
    width: 48%;
  }
  .titleWidth {
    width: 98%;
    margin: 0px 1%;
  }
  .landingMainText {
    font-size: 50px;
  }
  .menuBox {
    margin-right: 0px;
    margin-left: 0px;
  }
  .menuText {
    font-size: 13px;
  }
  .quickLinkCard {
    margin-right: 0px;
  }
}
